@import "../../../../../public/mixins/mixins.scss";
.stepWrapper {
  max-width: 150px;
  flex: 0 1 150px; // Reduced width
  .stepContainer {
    @include flexContainer(row, flex-start, center, 0, 10px); // Adjusted gap
    @include mobile() {
      column-gap: 3px; // Adjusted mobile gap
    }
    .stepNumContainer {
      @include flexContainer(column, center, center);
      .stepNum {
        @include flexContainer(row, center, center);
        @include font(14px, 500, #fff); // Reduced font size
        @include mobile() {
          width: 18px; // Reduced width
          height: 18px; // Reduced height
          font-size: 10px; // Reduced mobile font size
        }
        width: 36px; // Reduced width
        height: 36px; // Reduced height
        border-radius: 100%;
      }
      .verticalLine {
        height: 35px; // Reduced height
        border: 1px dashed;
      }
    }
    .stepHeading {
      @include font(12px, 500, unset, 0.96px); // Reduced font size
      @include mobile() {
        font-size: 10px; // Reduced mobile font size
      }
    }
  }
}
.borderBottom {
  width: 100%;
  border-radius: 6px;
  height: 6px; // Reduced height
  max-width: 150px;
}
