@import "../../../../public//mixins//mixins.scss";

.treatmentStepsWrapper {
  max-width: 1440px;
  padding: 70px 60px 35px 60px;
  padding-bottom: 0px;
  margin: auto;
  @include flexContainer(column, center, center, 60px);
  @include mobile() {
    padding: 40px 20px 30px 20px;
    row-gap: 20px;
  }

  .treatmentStepsTagline {
    text-align: center;
    @include font(32px, 300, #4a536a, 1.28px);
    @include mobile() {
      font-size: 18px;
    }
    .boldText {
      padding: 0px 10px;
      @include font(32px, 700, #006daf, 1.28px);
      @include mobile() {
        font-size: 18px;
      }
    }
  }
  .stepsContainer {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(150px, 1fr));
    grid-gap: 25px;
    width: 100%;

    @include mobile() {
      grid-template-columns: repeat(auto-fill, minmax(100px, 1fr));
      grid-gap: 20px;
    }
  }
  .noChargesContainer {
    padding: 10px 30px;
    text-align: center;
    border-radius: 40px;
    background: #f4f7ff;
    margin-bottom: 35px;
    @include font(18px, 500, #00bdda);
  }
}
